<template>
<div class="works">
<left-mnu-help :helpTextFn="helpTextFn"></left-mnu-help>
  <div class="main">
   <div v-html="h"></div>
  </div>
</div>
</template>

<script>
import LeftMnuHelp from "./components/LeftMnuHelp";
import {helpText_text} from "@/api";
export default {
  name: "HelpHome",
  components:{
    LeftMnuHelp,
  },
  data(){
    return{
      h:''
    }
  },
  methods:{
    helpTextFn(id){
      helpText_text(id).then(res=>{
        if(res.code===0){
          this.h=res.data
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/works.scss";
.main{
  border: none;
  background: rgba(0,0,0,0);

}
.pre-help{
  white-space: pre-wrap!important;
  word-wrap: break-word!important;
  *white-space:normal!important;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: LEFT;
  color: #666666;
}

</style>

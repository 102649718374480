<template>
  <el-menu
      active-text-color="#FF004D"
      background-color="#f7f7f7"
      @select="selectfn"
      :default-openeds="openeds"
  >
    <el-submenu v-for="item in leftList" :key="item.id" :index="item.id" v-show="item.list.length > 0" >
      <template slot="title">
        {{item.name}}
      </template>
      <el-menu-item-group>
        <el-menu-item v-for="i in item.list" :key="i.id" :index="item.id+'-'+i.id" >{{i.title}}</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
  </el-menu>
</template>

<script>
import {helpText_type} from '../../../api/index'
export default {
  props:{
  helpTextFn:Function
},
  name: 'leftMnuHelp',
  data() {
    return {
      leftList: [],
      openeds:[],
      currentIndex:''

    }
  },
  created() {
    this.leftmenufn()
    },
  methods:{
    leftmenufn(){
      helpText_type().then(res=>{
        if(res.code===0){
          this.leftList=res.data
          this.currentIndex=res.data[0].list[0].id
          res.data.forEach(item=>{
            if(item.list.length > 0){
              this.openeds.push(item.id)
            }
          })
          this.helpTextFn(this.currentIndex)
        }
      })
    },
    selectfn(index){
          console.log( index.split('-'))
      this.helpTextFn(index[index.length - 1])
    },
  }

}
</script>
<style lang="scss" scoped>
.el-menu {
  width: 200px;
  overflow: hidden;
  border: none;

  ::v-deep .el-submenu {
    .el-submenu__title {
      background-color: #f7f7f7 !important;
      font-size: 16px;
      font-family:PingFang SC-Medium;
      font-weight: 500;
      text-align: LEFT;
      color: #333333;
    }

    .el-menu-item {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Light;
      font-weight: 300;
      text-align: LEFT;
      color: #444444;
      background-color: #f7f7f7 !important;
    }
    .el-menu-item-group__title{
      padding: 0;
    }
  }
}
</style>
